import * as React from "react"
import HelmetSlot from "../components/Helmet"
import Navigation from "../components/Navigation"
import Footer from "../components/Footer"
import "../theme/main.less"
import SlickSlider from "../components/Slider"
import poolTeaser from "../images/swimpool.jpg"
import heizungTeaser from "../images/thermostat.webp"
import bathroomTeaser from "../images/bathroom.jpg"

import sliderDataFrontpage from "../data/sliderImages"

const IndexPage = () => {
  return (
    <>
      <HelmetSlot
        title="Gebäudetechnik Alexander Gast Siegburg - Schwimmbad und Heizungen"
        description="Wir kümmern uns für Sie um Schwimmbäder, Saunen, Heizungen, Sanitär, Bäder und Gasanlagen. Tel. 02241 1696682  E-Mail info@gt-ag.de"
      />
      <main>
        <body className="frontpage">
          <div className="view">
            <div className="landing">
              <Navigation />
              <div className="sliderarea">
                <SlickSlider
                  data={sliderDataFrontpage}
                  currentPage="frontpage"
                />
              </div>
            </div>
            <div className="main">
              <h2>Unsere Leistungen:</h2>
              <a
                href="/schwimmbad"
                alt="schwimmbad"
                className="teaser clickable"
              >
                <div className="schwimmbad">
                  <div className="text-wrapper right">
                    <h3>Schwimmbäder und Saunen</h3>
                    <p>
                      Unser Betrieb bietet langjährige Erfahrung im Bereich
                      Schwimmbad- und Saunabau. Wir sprechen mit ihnen über ihre
                      Wünsche und Vorstellungen, versuchen diese bestmöglich
                      umzusetzen und das alles zu fairen Konditionen. Bei der
                      Auswahl der Materialien zählen wir auf qualitativ
                      hochwertig ausgestattete Lieferanten und eine breite
                      Auswahl an verschiedensten Bodenbelägen, Größen,
                      Verkleidungen, Pumpen und werden ihren Sonderwünschen
                      gerecht.
                      <br></br>
                    </p>
                    <p>
                      Wollen Sie mehr zum Thema Sauna- und Schwimmbadbau
                      erfahren?
                      <br></br>
                      Hier finden sie mehr Informationen.
                    </p>
                  </div>
                  <div className="button-wrapper">
                    <a href="/schwimmbad" alt="Schwimmbad">
                      <button>Mehr zum Thema</button>
                    </a>
                  </div>
                  <img src={poolTeaser} alt="swimmingpool"></img>
                </div>
              </a>
              <a href="/heizungen" alt="Heizungen" className="teaser clickable">
                <div className="heizung">
                  <img src={heizungTeaser} alt="heizung"></img>
                  <div className="button-wrapper">
                    <a href="/heizungen" alt="Schwimmbad">
                      <button>Mehr zum Thema</button>
                    </a>
                  </div>
                  <div className="text-wrapper">
                    <h3>Heizungen und Gasanlagen</h3>
                    <p>
                      Ihre Wünsche und Vorstellungen im Bereich Heizung und
                      Sanitär setzen wir kompetent in passende Lösungen um. Ob
                      Gas, Öl oder Pellets- Heizungen, Brennwert-Technik oder
                      Wärmepumpen, wir beraten sie gerne und finden mit ihnen
                      gemeinsam die beste Lösung für ihre individuelle
                      Situation.<br></br>
                      <br></br>
                      Für mehr Informationen besuchen Sie unsere Heizungsseite.
                    </p>
                  </div>
                </div>
              </a>
              <a href="/baeder" alt="Bäder" className="teaser clickable">
                <div className="sanitaer" style={{ borderBottom: "none" }}>
                  <div className="text-wrapper right">
                    <h3>Bäder und Sanitär</h3>
                    <p>
                      Vom kleinen Gäste-WC bis zu Großanlagen in Vereinen,
                      Sportstätten oder Schulen - wir stehen bei Neubau,
                      Renovierungen oder Reparaturen an ihrer Seite. Wir stellen
                      die fachgerechte Auswahl, Lieferung und Montage der
                      technischen Gewerke (Waschtische, Duschen, Toiletten,
                      Bidets) sicher, sowie die Montage aller Ver- und
                      Entsorgungsleitungen.
                    </p>
                    <p>
                      Wollen Sie mehr zum Thema Bäder erfahren?<br></br>
                      Hier finden sie mehr Informationen.
                    </p>
                  </div>
                  <div className="button-wrapper">
                    <a href="/baeder" alt="Schwimmbad">
                      <button>Mehr zum Thema</button>
                    </a>
                  </div>
                  <img src={bathroomTeaser} alt="Badezimmer"></img>
                </div>
              </a>
            </div>
            <Footer />
          </div>
        </body>
      </main>
    </>
  )
}

export default IndexPage
