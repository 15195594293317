import * as React from "react"
import PropTypes from "prop-types"
import Slider from "react-slick"

import leftArrowSVG from "./slider-previous.svg"
import rightArrowSVG from "./slider-next.svg"

const SlickSlider = ({ data, currentPage }) => {
  SlickSlider.propTypes = {
    className: PropTypes.string,
    style: PropTypes.func,
    onClick: PropTypes.func,
    currentPage: PropTypes.string,
    data: PropTypes.objectOf(PropTypes.any).isRequired,
  }

  const PrevArrow = ({ className, style, onClick }) => (
    <button
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    >
      <img src={leftArrowSVG} width="36" height="36" alt="Previous" />
    </button>
  )

  const NextArrow = ({ className, style, onClick }) => (
    <button
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    >
      <img src={rightArrowSVG} width="36" height="36" alt="Previous" />
    </button>
  )

  return (
    <Slider
      className={currentPage}
      dots
      dotsClass="slick-dots slick-thumb"
      infinite
      speed={500}
      slidesToShow={1}
      slidesToScroll={1}
      arrows={true}
      nextArrow={<NextArrow />}
      prevArrow={<PrevArrow />}
    >
      {data.map((item, index) => (
        <div key={item.id}>
          <div className={`slide slide-${index + 1}`}>
            <div className="slide-body-text">
              <div className="wrapper">
                <h3>{item.title}</h3>
              </div>
              <p>{item.description}</p>
              <div className="button-wrapper">
                <a href={`/${item.url}`} alt={item.id}>
                  <button>Mehr erfahren</button>
                </a>
              </div>
            </div>
          </div>
        </div>
      ))}
    </Slider>
  )
}

export default SlickSlider
