const sliderDataFrontpage = [
  {
    id: "poolTeaser",
    url: "schwimmbad",
    title: "Schwimmbäder",
    description:
      "Vom einfachen Schwimmbecken bis zum hochqualitativen Einstückbecken. Wir erfüllen Ihren Traum vom eigenen Schwimmbad.",
  },
  {
    id: "saunaTeaser",
    url: "schwimmbad",
    title: "Saunabau",
    description:
      "Ob Innen- oder Außensauna, eine Sauna hilft bei der alltäglichen Erholung, ist gesund und gut für den Kreislauf. Wir helfen Ihnen dabei.",
  },
  {
    id: "heizungenSanitaer",
    url: "heizungen",
    title: "Heizungen",
    description:
      "Wir bieten Reparaturen, Wartungen, Installationen von Heizungs- und Gasanlagen. Damit es bei Ihnen zuhause immer warm bleibt.",
  },
  {
    id: "baeder",
    url: "baeder",
    title: "Bäder & Sanitär",
    description:
      "Wir helfen bei der Erneuerung ihres Bades, bei Renovierung oder Komplettumbau. Egal ob im Eigenheim, Büro oder Vereinsheim.",
  },
  {
    id: "gasanlagen",
    url: "heizungen",
    title: "Gasanlagen",
    description:
      "Wir warten, reparieren und installieren ihre neue oder alte Gasanlage. Wir lassen nichts anbrennen.",
  },
  {
    id: "kontakt",
    url: "kontakt",
    title: "Kontakt",
    description:
      "Sie möchten wissen, wie Sie uns erreichen können? Besuchen Sie unsere Kontaktseite. Dort finden Sie auch Anfahrtsmöglichkeiten.",
  },
]

export default sliderDataFrontpage
